<template>
  <div :class="userPicType">
    <div class="d-inline-block position-relative">
      <div :class="isLoading ? 'spinner-border spinner-border-sm' : ''"></div>
      <LazyImage
        v-if="userPic"
        :src="`${userPic}&width=160&height=160&fit=cover`"
        :width="160"
        :height="160"
        class="user-pic rounded-circle"
        alt="user photo"
        fluid
      />
      <div
        v-else
        class="d-flex align-items-center justify-content-center user-pic user-pic__icon"
      >
        <UserIcon width="18px" height="18px" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserIcon from '~/assets/icons/user.svg'
import LazyImage from '~/components/LazyImage'

export default {
  components: {
    UserIcon,
    LazyImage,
  },
  props: {
    userPic: {
      type: String,
      default: null,
    },
    userPicType: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      loggedInUser: 'loggedInUser',
    }),
  },
}
</script>
<style lang="scss" scoped>
.profile {
  .user-pic {
    height: 80px;
    width: 80px;

    &.user-pic__icon {
      svg {
        height: 40px;
        width: 40px;
      }
    }
  }
}

.login {
  .user-pic {
    border: none;
    height: 140px;
    width: 140px;
    max-width: unset;

    &.user-pic__icon {
      svg {
        height: 48px;
        width: 48px;
      }
    }
  }
}

.user-menu {
  .user-pic {
    border: 2px solid $white;
    height: 44px;
    width: 44px;
  }
}

.user-pic__icon {
  background-color: $white;
  border-radius: 50%;
}

.spinner-border {
  color: $white;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  z-index: 5;

  + .user-pic {
    opacity: 0.6;
  }
}

.user-reviews {
  .user-pic {
    border: 0;
    height: 60px;
    width: 60px;
  }

  .user-pic__icon {
    background: $white;
    border-radius: 50%;
    height: 60px;
    width: 60px;
  }
}
</style>
